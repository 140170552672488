/* OLD */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* NEW */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  margin-bottom: 20px;
}

.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-section,
.answer-section {
  margin-bottom: 20px;
}

button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #2088ff;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
}

button:hover {
  background-color: #1177cc;
}

.score-section {
  font-size: 24px;
  font-weight: bold;
}

.explanation-section {
  margin-bottom: 20px;
}

.explanation-text {
  margin-bottom: 10px;
}


/* New */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  color: #212529;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.quote {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
}

.quote-author {
  font-weight: 500;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 10px;
}

.quiz-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.quiz-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.quiz-card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.quiz-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

button {
background-color: #007bff;
border: none;
border-radius: 4px;
color: #ffffff;
cursor: pointer;
font-size: 1rem;
font-weight: 500;
margin: 10px 0;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
transition-duration: 0.4s;
}

button:hover {
background-color: #0056b3;
color: #ffffff;
}

button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

option {
font-size: 1rem;
padding: 5px 10px;
}

@media screen and (max-width: 768px) {
  h1 {
  font-size: 1.5rem;
  }

  .quiz-title {
  font-size: 1.2rem;
  }

  .quote {
  font-size: 1rem;
  }

  .quote-author {
  font-size: 0.9rem;
  }
}